import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScenarioService } from '../services/scenario.service';
import * as WebFont from 'webfontloader';
import { PlayerSessionService } from '../services/player-session.service';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';

declare function ScormProcessInitialize(): any;
declare const window: any;
declare var xapiActor:any;
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  scenario: any;
  startStep: any;
  saveSessionInterval: any;
  sessionCreated = false;
  scenarioId: any;
  publishedHash: any;
  user: any = {};
  activeSessionId: any;
  savingSession = false;
  mode = 'hosted';
  isActor = false;
  isLoading = true;
  notFound = false;
  unavailable = false;
  unavailableError:any;

  constructor(
    // private sw: UpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private scenarioService: ScenarioService,
    private playerSessionService: PlayerSessionService,
    private meta: Meta,
    private titleService:Title
  ) {
    // check the service worker for updates
    // this.sw.checkForUpdates();
  }
  ngOnInit() {
    // if it's local or scorm we'll have the meta data so get that
    if (this.meta.getTag('name=ys-hash')) {
      this.mode = 'local';

      // are we scorm?
      if (this.meta.getTag('name=scorm')?.content) {
        this.mode = 'scorm';
      }

      // are we xapi?
      if (this.meta.getTag('name=xapi')?.content) {
        this.mode = 'xapi';
      }

      if (this.meta.getTag('name=xapi')?.content && this.meta.getTag('name=scorm')?.content) {
        this.mode = 'xapi-scorm';
      }


      if (this.meta.getTag('name=xapi-ysactor')?.content) {
        this.isActor = true;
        this.playerSessionService.userDataSet.subscribe((isSet)=>{
          if(isSet){
            // get the name
            let actorName ='unknown';
            if(this.playerSessionService.sessionData.user.name){
              actorName = this.playerSessionService.sessionData.user.name 
            }
            else if(this.playerSessionService.sessionData.user.Name){
              actorName = this.playerSessionService.sessionData.user.Name 
            }
            else if(this.playerSessionService.sessionData.user['NAME']){
              actorName = this.playerSessionService.sessionData.user['NAME'] 
            }

            let actorMbox ='unknown';
            if(this.playerSessionService.sessionData.user.email){
              actorMbox = this.playerSessionService.sessionData.user.email 
            }
            else if(this.playerSessionService.sessionData.user.Email){
              actorMbox = this.playerSessionService.sessionData.user.Email 
            }
            else if(this.playerSessionService.sessionData.user['EMAIL']){
              actorMbox = this.playerSessionService.sessionData.user['EMAIL'] 
            }
            xapiActor = {
              name: actorName,
              mbox: actorMbox,
            };
          }
        });
      }

      if (this.mode == 'scorm' || this.mode == 'xapi-scorm') {
       
        ScormProcessInitialize();
        var lmsAPI = window.API;
        // console.log(lmsAPI);
        lmsAPI.LMSInitialize('');
        lmsAPI.LMSSetValue('cmi.core.lesson_status', 'incomplete');
        lmsAPI.LMSCommit();
      }
      else if(this.mode == 'xapi'){

      }

      let hash = this.meta.getTag('name=ys-hash')?.content;
      let hashParts: any = [];
      hashParts = hash?.split('-');
      if (!hashParts[0] || !hashParts[1] || !hashParts[2]) {
        // console.log('Not found');

        this.isLoading = false;
        this.notFound = true;
      } else {
        // load it
        this.scenarioId = hashParts[1];
        this.publishedHash = hashParts[2];
        this.scenarioService
          .loadScenario(hashParts[0] + '/' + hashParts[2] + '.json', 'local')
          .subscribe((responseData) => {
            this.initScenario(responseData, hashParts);
            this.isLoading = false;
            
          });
      }
    } else {
      // get the stuff..
      this.route.params.subscribe((params: any) => {
        let hash = params['hash'];
        if (hash) {
          let hashParts = hash.split('-');
          if (!hashParts[0] || !hashParts[1] || !hashParts[2]) {
            // console.log('Not found');
          } else {
            // load it
            this.scenarioId = hashParts[1];
            this.publishedHash = hashParts[2];
            this.scenarioService
              .loadScenario(hashParts[0] + '/' + hashParts[2] + '.json')
              .subscribe((responseData) => {
                this.initScenario(responseData, hashParts);
                this.isLoading = false;
              });
            this.scenarioService.checkScenario(this.scenarioId).subscribe((response)=>{}, (error)=>{
              // an error so we're not allowed...
              this.unavailable = true;
              this.unavailableError = error.error;

            });
          }
        }
      });
    }
  }

  initScenario(scenario: any, hashParts: any) {
    this.scenario = scenario;

    // set the title
    this.titleService.setTitle(scenario.title);

    // max attempts?
    if (this.scenario.settings?.maxAttempts) {
      let attempts = localStorage.getItem(
        'ys-' + '-' + hashParts[0] + '-' + hashParts[2] + '-attemps'
      );
      let numAttempts = 0;
      if (!attempts) {
        attempts = '0';
      } else {
        numAttempts = +attempts;
      }
      // add this attempts
      numAttempts++;
      if (numAttempts > +this.scenario.settings?.maxAttempts) {
        // max reached..
        Swal.fire({
          icon: 'error',
          title: 'Maximum attempts reached',
          text: 'You have reached the maximum number of attempts allowed at this scenario.',
          showConfirmButton: false,
        });
      } else {
        localStorage.setItem(
          'ys-' + '-' + hashParts[0] + '-' + hashParts[2] + '-attemps',
          '' + numAttempts
        );
      }
    }

    this.startStep = this.scenarioService.getStartStep(this.scenario);
    // init stuff
    this.scenarioService.loadFonts(this.scenario);
    // start the save session interval
    this.saveSessionInterval = setInterval(() => {
      this.saveSessionData();
    }, 10000);

    // get the session
    this.playerSessionService.sessionInitialised.subscribe(
      (responseData) => {}
    );

    this.playerSessionService.sessionLogUpdated.subscribe((responseData) => {
      if (
        responseData == true &&
        !this.sessionCreated &&
        !this.savingSession &&
        this.mode == 'hosted'
      ) {
        this.savingSession = true;
        this.playerSessionService
          .create(
            this.scenarioId,
            this.playerSessionService.sessionData.id,
            this.publishedHash,
            JSON.stringify(this.playerSessionService.sessionData.user),
            JSON.stringify(this.playerSessionService.sessionData),
            JSON.stringify(this.playerSessionService.sessionLog),
            this.playerSessionService.sessionData.score,
            JSON.stringify(this.playerSessionService.sessionData.vars),
            JSON.stringify(this.playerSessionService.sessionData.route),
            this.playerSessionService.sessionData.duration,
            this.playerSessionService.sessionData.route.slice(-1).pop(),
            dayjs(this.playerSessionService.sessionData.lastActive).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          )
          .subscribe((sessionResponseData) => {
            this.sessionCreated = true;
            this.activeSessionId =
              sessionResponseData.body?.scenario_session_id;
            this.savingSession = false;
          });
        //this.playerSessionService.sessionLogUpdated.unsubscribe();
      }
    });
  }

  saveSessionData() {
    if (this.activeSessionId && this.mode == 'hosted') {
      this.playerSessionService
        .update(
          this.activeSessionId,
          this.scenarioId,
          this.playerSessionService.sessionData.id,
          this.publishedHash,
          JSON.stringify(this.playerSessionService.sessionData.user),
          JSON.stringify(this.playerSessionService.sessionData),
          JSON.stringify(this.playerSessionService.sessionLog),
          this.playerSessionService.sessionData.score,
          JSON.stringify(this.playerSessionService.sessionData.vars),
          JSON.stringify(this.playerSessionService.sessionData.route),
          this.playerSessionService.sessionData.duration,
          this.playerSessionService.sessionData.route[this.playerSessionService.sessionData.route.length -1],
          dayjs(this.playerSessionService.sessionData.lastActive).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        )
        .subscribe((sessionResponseData) => {
         //  console.log('session saved');
        });
    }
  }

  ngOnDestroy() {
    clearInterval(this.saveSessionInterval);
  }

  @HostListener('window:unload', ['$event'])
  beforeUnloadHander(event: any) {
    this.saveSessionData();
  }
}
