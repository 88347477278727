import { Injectable, Sanitizer, SecurityContext } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlayerService } from './player.service';
import { toJSON } from 'css-convert-json';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  shapes = environment.shapes;
  public refreshContent = new BehaviorSubject(false);
  public refreshChart = new BehaviorSubject(null);
  constructor(
    private playerService: PlayerService,
    private _sanitizer: DomSanitizer
  ) {}

  getShape(component: any): string {
    let shapeId = component.content;
    let shapeSvg = '';
    this.shapes.forEach((shape: any) => {
      if (shape.id == shapeId) {
        shapeSvg = shape.svg;
      }
    });

    if (component.data?.description) {
      shapeSvg = shapeSvg.replace(
        '<svg',
        '<svg aria-describedby="' + component.id + '-desc"'
      );
      shapeSvg = shapeSvg.replace(
        '</svg>',
        '<title>' + component.data?.description + '</title></svg>'
      );
      shapeSvg = shapeSvg.replace(
        '</svg>',
        '<desc id="' +
          component.id +
          '-desc">' +
          component.data?.description +
          '</desc></svg>'
      );
    }
    return shapeSvg;
  }

  getIcon(component: any): string {
    let iconSvg = component.data.svgPath;

    if (component.data?.description) {
      iconSvg = iconSvg.replace(
        '<svg',
        '<svg aria-describedby="' + component.id + '-desc"'
      );
      iconSvg = iconSvg.replace(
        '</svg>',
        '<title>' + component.data?.description + '</title></svg>'
      );
      iconSvg = iconSvg.replace(
        '</svg>',
        '<desc id="' +
          component.id +
          '-desc">' +
          component.data?.description +
          '</desc></svg>'
      );
    }
    return iconSvg;
  }

  getComponentStyle(
    component: any,
    mode: string = 'editor',
    scenario: any = null
  ) {
    // console.log('comp style');
    let style: any;
    style = {};
    //style['width'] = component.width;
    //style['height'] = component.height;

    if (component.style.padding != undefined) {
      // is it split notation?
      let padding = '0';
      if (String(component.style.padding).indexOf(' ') >= 0) {
        padding = component.style.padding.replaceAll(' ', 'px ') + 'px';
      } else {
        padding = component.style.padding + 'px';
      }
      style['padding'] = padding;
      style['--ys-component-padding'] = padding;
    }
    if (component.style.bg) {
      style['background-color'] = component.style.bg;
      style['background'] = component.style.bg;
      style['--ys-component-bg'] = component.style.bg;
      style['--ys-component-bubbleBg'] = component.style.bg;
    }
    if (component.style.color) {
      style['color'] = component.style.color;
      style['--ys-component-color'] = component.style.color;
    }
    if (component.style.textColor) {
      style['color'] = component.style.textColor;
      style['--ys-text-color'] = component.style.textColor;
    }
    if (component.style.radius != undefined) {
      style['border-radius'] = component.style.radius + 'px';
      style['--ys-component-radius'] = component.style.radius + 'px';
    }

    if (component.type != 'choices') {
      if (component.style.textAlign) {
        style['text-align'] = component.style.textAlign;
        style['--ys-component-text-align'] = component.style.textAlign;
      }
      if (component.style.fontSize) {
        style['font-size'] = component.style.fontSize + '%';
        style['--ys-component-font-size'] = component.style.fontSize + '%';
      }
      if (component.style.fontWeight) {
        style['font-weight'] = component.style.fontWeight;
        style['--ys-component-font-weight'] = component.style.fontWeight;
      }
      if (component.style.lineHeight) {
        style['line-height'] = component.style.lineHeight + '%';
        style['--ys-component-line-height'] = component.style.lineHeight + '%';
      }
      if (component.style.letterSpacing) {
        style['letter-spacing'] =
          (component.style.letterSpacing - 100) / 100 + 'em';
        style['--ys-component-letter-spacing'] =
          (component.style.letterSpacing - 100) / 100 + 'em';
      }
    }
    if (component.style.opacity || component.style.opacity == 0) {
      style['opacity'] = component.style.opacity + '%';
    }
    if (component.style.blur) {
      style['filter'] = 'blur(' + component.style.blur + 'px)';
    }
    if (component.style.flexDirection) {
      style['--ys-component-flex-direction'] = component.style.flexDirection;
    }
    if (component.style.flexAlign) {
      style['--ys-component-flex-align'] = component.style.flexAlign;
    }
    if (component.style.flexJustify) {
      style['--ys-component-flex-justify'] = component.style.flexJustify;
      if (component.style.flexAlign == 'stretch') {
        //style['flex-grow'] = 1;
      }
    }
    if (component.style.textAlign) {
      style['--ys-component-text-align'] = component.style.textAlign;
    }
    // choice style vars

    if (component.style.choiceFontWeight) {
      style['--ys-component-choice-font-weight'] =
        component.style.choiceFontWeight;
    }
    if (component.style.choiceFontSize) {
      style['--ys-component-choice-font-scale'] =
        component.style.choiceFontSize + '%';
    }
    if (component.style.choiceLineHeight) {
      style['--ys-component-choice-line-height'] =
        component.style.choiceLineHeight + '%';
    }
    if (component.style.choiceLetterSpacing) {
      style['--ys-component-choice-letter-spacing'] =
        (component.style.choiceLetterSpacing - 100) / 100 + 'em';
    }

    if (component.style.choiceMargin) {
      style['--ys-component-choice-margin'] =
        component.style.choiceMargin + 'px';
    }
    if (component.style.choicePadding != undefined) {
      // is it split notation?
      let padding = '0';
      if (String(component.style.choicePadding).indexOf(' ') >= 0) {
        padding = component.style.choicePadding.replaceAll(' ', 'px ') + 'px';
      } else {
        padding = component.style.choicePadding + 'px';
      }
      style['--ys-component-choice-padding'] = padding;
    }
    if (component.style.choiceColor) {
      style['--ys-component-choice-color'] = component.style.choiceColor;
    }
    if (component.style.choiceBg) {
      style['--ys-component-choice-bg'] = component.style.choiceBg;
    }
    if (component.style.choiceRadius || component.style.choiceRadius == 0) {
      style['--ys-component-choice-radius'] =
        component.style.choiceRadius + 'px';
    }
    if (component.style.choiceOpacity) {
      style['--ys-component-choice-opacity'] =
        component.style.choiceOpacity + '%';
    }
    if (component.style.choiceBlur) {
      style['--ys-component-choice-blur'] = component.style.choiceBlur + 'px';
    }

    if (
      component.style.choiceBorder &&
      component.style.choiceBorder.width &&
      component.style.choiceBorder.style &&
      component.style.choiceBorder.color
    ) {
      style['--ys-component-choice-border'] =
        component.style.choiceBorder.width +
        'px ' +
        component.style.choiceBorder.style +
        ' ' +
        component.style.choiceBorder.color;
    }

    // choice hover
    if (component.style.choiceHoverColor) {
      style['--ys-component-choice-hover-color'] =
        component.style.choiceHoverColor;
    }
    if (component.style.choiceHoverBg) {
      style['--ys-component-choice-hover-bg'] = component.style.choiceHoverBg;
    }

    if (
      component.style.choiceHoverBorder &&
      component.style.choiceHoverBorder.width &&
      component.style.choiceHoverBorder.style &&
      component.style.choiceHoverBorder.color
    ) {
      style['--ys-component-choice-hover-border'] =
        component.style.choiceHoverBorder.width +
        'px ' +
        component.style.choiceHoverBorder.style +
        ' ' +
        component.style.choiceHoverBorder.color;
    }

    //shadow
    if (component.style.choiceShadow && component.style.choiceShadow.offsetX) {
      style['--ys-component-choice-shadow'] =
        component.style.choiceShadow.offsetX +
        'px ' +
        component.style.choiceShadow.offsetY +
        'px ' +
        component.style.choiceShadow.blur +
        'px ' +
        component.style.choiceShadow.spread +
        'px ' +
        component.style.choiceShadow.color;
    }

    if (component.style.fill) {
      style['--ys-component-fill'] = component.style.fill;
    }

    // fonts...
    if (component.style.font) {
      switch (component.style.font) {
        case 'body':
          style['font-family'] = 'var(--ys-font-base)';
          break;
        case 'sans':
          style['font-family'] = 'var(--ys-font-sans)';
          break;
        case 'serif':
          style['font-family'] = 'var(--ys-font-serif)';
          break;
        case 'heading':
          style['font-family'] = 'var(--ys-font-heading)';
          break;
        case 'mono':
          style['font-family'] = 'var(--ys-font-mono)';
          break;
        case 'script':
          style['font-family'] = 'var(--ys-font-script)';
          break;
        case 'custom':
          // load the custom font!
          style['font-family'] = component.style.fontFamily;
          break;
      }
    }

    // font input
    if (component.style.fontInput) {
      switch (component.style.fontInput) {
        case 'body':
          style['--ys-input-font'] = 'var(--ys-font-base)';
          break;
        case 'sans':
          style['--ys-input-font'] = 'var(--ys-font-sans)';
          break;
        case 'serif':
          style['--ys-input-font'] = 'var(--ys-font-serif)';
          break;
        case 'heading':
          style['--ys-input-font'] = 'var(--ys-font-heading)';
          break;
        case 'mono':
          style['--ys-input-font'] = 'var(--ys-font-mono)';
          break;
        case 'script':
          style['--ys-input-font'] = 'var(--ys-font-script)';
          break;
        case 'custom':
          // load the custom font!
          style['--ys-input-font'] = component.style.fontFamilyInput;
          break;
      }
    }

    // font label
    if (component.style.font) {
      switch (component.style.font) {
        case 'body':
          style['--ys-label-font'] = 'var(--ys-font-base)';
          break;
        case 'sans':
          style['--ys-label-font'] = 'var(--ys-font-sans)';
          break;
        case 'serif':
          style['--ys-label-font'] = 'var(--ys-font-serif)';
          break;
        case 'heading':
          style['--ys-label-font'] = 'var(--ys-font-heading)';
          break;
        case 'mono':
          style['--ys-label-font'] = 'var(--ys-font-mono)';
          break;
        case 'script':
          style['--ys-label-font'] = 'var(--ys-font-script)';
          break;
        case 'custom':
          // load the custom font!
          style['--ys-label-font'] = component.style.fontFamily;
          break;
      }
    }

    //outline
    if (
      component.style.outline &&
      component.style.outline.width &&
      component.style.outline.style &&
      component.style.outline.color
    ) {
      style['--ys-component-outline'] =
        component.style.outline.width +
        'px ' +
        component.style.outline.style +
        ' ' +
        component.style.outline.color;
    }

    //shadow
    if (
      component.type == 'button' &&
      scenario.style?.buttonShadow &&
      scenario.style?.buttonShadow?.offsetX
    ) {
      style['--ys-component-shadow'] =
        scenario.style.buttonShadow.offsetX +
        'px ' +
        scenario.style.buttonShadow.offsetY +
        'px ' +
        scenario.style.buttonShadow.blur +
        'px ' +
        scenario.style.buttonShadow.spread +
        'px ' +
        scenario.style.buttonShadow.color;
    }
    if (component.style.shadow && component.style.shadow.offsetX) {
      style['--ys-component-shadow'] =
        component.style.shadow.offsetX +
        'px ' +
        component.style.shadow.offsetY +
        'px ' +
        component.style.shadow.blur +
        'px ' +
        component.style.shadow.spread +
        'px ' +
        component.style.shadow.color;
    }

    //text shadow
    if (component.style.textShadow && component.style.textShadow.offsetX) {
      style['--ys-component-text-shadow'] =
        component.style.textShadow.offsetX +
        'px ' +
        component.style.textShadow.offsetY +
        'px ' +
        component.style.textShadow.blur +
        'px ' +
        component.style.textShadow.color;

      style['--ys-component-svg-shadow'] =
        'drop-shadow(' +
        component.style.textShadow.offsetX +
        'px ' +
        component.style.textShadow.offsetY +
        'px ' +
        component.style.textShadow.blur +
        'px ' +
        component.style.textShadow.color +
        ')';
    }

    // svg stroke?
    if (component.style.strokeWidth && component.style.strokeColor) {
      style['stroke'] = component.style.strokeColor;

      if (component.type == 'shape') {
        style['stroke-width'] = +component.style.strokeWidth / 10 + 'px';
      } else {
        style['stroke-width'] = +component.style.strokeWidth + 'px';
      }

      style['stroke-linejoin'] = 'round';
      style['paint-order'] = 'stroke';
    }

    if (mode == 'player' || mode == 'editor') {
      // choices?
      if (
        component.type == 'choices' &&
        scenario.style.choiceAnimation &&
        !component.style.animationIn
      ) {
        if (scenario.style.choiceAnimation.animationIn) {
          if (typeof scenario.style.choiceAnimation.animationIn == 'string') {
            style['--animate-duration'] =
              scenario.style.choiceAnimation.animationInSpeed + 's';
            style['--animate-delay'] =
              scenario.style.choiceAnimation.animationInDelay + 's';

            if (scenario.style.choiceAnimation.animationInRepeat) {
              if (
                scenario.style.choiceAnimation.animationInRepeat !== 'infinite'
              ) {
                style['--animate-repeat'] =
                  +scenario.style.choiceAnimation.animationInRepeat;
              }
            }
          } else {
            style['--animate-duration'] =
              scenario.style.choiceAnimation.animationIn.speed + 's';
            style['--animate-delay'] =
              scenario.style.choiceAnimation.animationIn.delay + 's';

            if (scenario.style.choiceAnimation.animationIn.repeat) {
              if (
                scenario.style.choiceAnimation.animationIn.repeat !== 'infinite'
              ) {
                style['--animate-repeat'] =
                  +scenario.style.choiceAnimation.animationIn.repeat;
              }
            }
          }
        }
      }

      // animation // new style!
      if (component.animationIn) {
        if (typeof component.animationIn == 'string') {
          style['--animate-duration'] = component.animationInSpeed + 's';
          style['--animate-delay'] = component.animationInDelay + 's';

          if (component.animationInRepeat) {
            if (component.animationInRepeat !== 'infinite') {
              style['--animate-repeat'] = +component.animationInRepeat;
            }
          }
        } else {
          if (component.animationIn.name != '') {
            style['--animate-duration'] = component.animationIn.speed + 's';
            style['--animate-delay'] = component.animationIn.delay + 's';

            if (component.animationIn.repeat) {
              if (component.animationIn.repeat !== 'infinite') {
                style['--animate-repeat'] = +component.animationIn.repeat;
              }
            }
          } else if (
            component.animationDuring &&
            component.animationDuring.name != ''
          ) {
            style['--animate-duration'] = component.animationDuring.speed + 's';
            style['--animate-delay'] = component.animationDuring.delay + 's';

            if (component.animationDuring.repeat) {
              if (component.animationDuring.repeat !== 'infinite') {
                style['--animate-repeat'] = +component.animationDuring.repeat;
              }
            }
          }
        }
      }

      if (component.hidden && mode == 'player') {
        style['display'] = 'none';
      }
    }

    // chat
    if (component.style.bubbleBg) {
      style['--ys-component-bubbleBg'] = component.style.bubbleBg;
    }
    if (component.style.bubbleColor) {
      style['--ys-component-bubbleColor'] = component.style.bubbleColor;
    }

    if (component.style.optionBubbleBg) {
      style['--ys-component-optionBubbleBg'] = component.style.optionBubbleBg;
    }
    if (component.style.optionBubbleColor) {
      style['--ys-component-optionBubbleColor'] =
        component.style.optionBubbleColor;
    }
    if (component.style.avatarRadius != undefined) {
      style['--ys-component-avatarRadius'] =
        component.style.avatarRadius + 'px';
    }

    if (component.style.avatarPosition) {
      style['--ys-component-avatar-position'] = component.style.avatarPosition;
    }

    if (component.style.chatPosition) {
      style['--ys-component-chat-position'] = component.style.chatPosition;
    }
    if (component.style.dividerColor) {
      style['--ys-component-divider-color'] = component.style.dividerColor;
    }

    // buttons

    if (component.style.buttonPadding) {
      if (component.style.buttonPadding != undefined) {
        // is it split notation?
        let padding = '0';
        if (String(component.style.buttonPadding).indexOf(' ') >= 0) {
          padding = component.style.buttonPadding.replaceAll(' ', 'px ') + 'px';
        } else {
          padding = component.style.buttonPadding + 'px';
        }
        style['--ys-component-button-padding'] = padding;
      }
    }

    if (component.style.buttonFontSize) {
      style['--ys-component-button-font-size'] =
        component.style.buttonFontSize + '%';
    }

    if (component.style.buttonFontSize) {
      style['--ys-component-button-font-size'] =
        component.style.buttonFontSize + '%';
    }

    if (component.style.buttonLetterSpacing) {
      style['--ys-component-button-letter-spacing'] =
        (component.style.buttonLetterSpacing - 100) / 100 + 'em';
    }

    if (component.style.buttonRadius != undefined) {
      style['--ys-component-button-radius'] =
        component.style.buttonRadius + 'px';
    }
    if (component.style.textAlign) {
      style['--ys-component-button-align'] = component.style.textAlign;
    }

    if (component.style.buttonBg) {
      style['--ys-component-button-bg'] = component.style.buttonBg;
    }
    if (component.style.buttonBorder && component.style.buttonBorder.width) {
      style['--ys-component-button-border'] =
        component.style.buttonBorder.width +
        'px ' +
        component.style.buttonBorder.style +
        ' ' +
        component.style.buttonBorder.color;
    }

    if (component.style.buttonOutline) {
      style['--ys-component-button-outline'] =
        component.style.buttonOutline.width +
        'px ' +
        component.style.buttonOutline.style +
        ' ' +
        component.style.buttonOutline.color;
    }
    if (component.style.buttonColor) {
      style['--ys-component-button-color'] = component.style.buttonColor;
    }
    if (component.style.buttonHoverBg) {
      style['--ys-component-button-hover-bg'] = component.style.buttonHoverBg;
    }
    if (
      component.style.buttonHoverBorder &&
      component.style.buttonHoverBorder.width
    ) {
      style['--ys-component-button-hover-border'] =
        component.style.buttonHoverBorder.width +
        'px ' +
        component.style.buttonHoverBorder.style +
        ' ' +
        component.style.buttonHoverBorder.color;
    }
    if (component.style.buttonHoverOutline) {
      style['--ys-component-button-hover-outline'] =
        component.style.buttonHoverOutline.width +
        'px ' +
        component.style.buttonHoverOutline.style +
        ' ' +
        component.style.buttonHoverOutline.color;
    }
    if (component.style.buttonHoverColor) {
      style['--ys-component-button-hover-color'] =
        component.style.buttonHoverColor;
    }

    if (component.style.gridCols) {
      style['--ys-component-grid-cols'] =
        'repeat(' + component.style.gridCols + ', 1fr)';
    }
    if (component.style.gridGap) {
      style['--ys-component-grid-gap'] = component.style.gridGap + 'px';
    }

    //

    // inputs

    if (component.style.inputColor) {
      style['--ys-input-color'] = component.style.inputColor;
    }
    if (component.style.inputBg) {
      style['--ys-input-bg'] = component.style.inputBg;
    }
    if (component.style.inputPlaceholder) {
      style['--ys-input-placeholder'] = component.style.inputPlaceholder;
    }
    if (component.style.inputFocus) {
      style['--ys-input-focus'] = component.style.inputFocus;
    }
    if (component.style.textColor) {
      style['--ys-label-color'] = component.style.textColor;
    }

    // quiz
    if (component.style.answerBg) {
      style['--ys-component-answer-bg'] = component.style.answerBg;
    }
    if (component.style.answerBgHover) {
      style['--ys-component-answer-bg-hover'] = component.style.answerBgHover;
    } else {
      if (scenario?.style?.primaryColor) {
        style['--ys-component-answer-bg-hover'] = scenario.style.primaryColor;
      }
    }
    if (component.style.answer) {
      style['--ys-component-answer'] = component.style.answer;
    }
    if (component.style.answerHover) {
      style['--ys-component-answer-hover'] = component.style.answerHover;
    }
    if (component.style.answerCorrectColor) {
      style['--ys-component-answer-color-correct'] =
        component.style.answerCorrectColor;
    }
    if (component.style.answerCorrectBg) {
      style['--ys-component-answer-bg-correct'] =
        component.style.answerCorrectBg;
    }
    if (component.style.answerIncorrectColor) {
      style['--ys-component-answer-color-incorrect'] =
        component.style.answerIncorrectColor;
    }
    if (component.style.answerIncorrectBg) {
      style['--ys-component-answer-bg-incorrect'] =
        component.style.answerIncorrectBg;
    }

    // tables

    if (component.style.thBg) {
      style['--ys-component-th-bg-color'] = component.style.thBg;
    }
    if (component.style.thText) {
      style['--ys-component-th-color'] = component.style.thText;
    }
    if (component.style.trBg) {
      style['--ys-component-tr-bg-color'] = component.style.trBg;
    }
    if (component.style.trText) {
      style['--ys-component-tr-color'] = component.style.trText;
    }
    if (component.style.trAltBg) {
      style['--ys-component-tr-alt-bg-color'] = component.style.trAltBg;
    }

    // hotspot
    if (component.style.iconColor) {
      style['--ys-component-icon-color'] = component.style.iconColor;
    }
    if (component.style.iconBg) {
      style['--ys-component-icon-bg'] = component.style.iconBg;
    }

    // custom css
    if (component.style.css) {
      try {
        let componentCss = toJSON(component.style.css).attributes;
        for (let key in componentCss) {
          if (CSS.supports(key, componentCss[key])) {
            style[key] = componentCss[key];
          }
        }
      } catch (error: any) {}
    }

    let customCss = '';
    if (component.style.cssAfter) {
      try {
        let componentCss = toJSON(component.style.cssAfter).attributes;
        var componentAfterCss = '';
        for (let key in componentCss) {
          if (CSS.supports(key, componentCss[key])) {
            componentAfterCss += key + ':' + componentCss[key] + ';';
          }
        }
        // now this is a bit naighty.  Add css to the head here...
        // remove the old
        customCss =
          '#component-ys-' +
          component.id +
          '::after, #component-' +
          component.id +
          '::after{' +
          componentAfterCss +
          '}';
      } catch (error: any) {}
    }

    if (component.style.cssBefore) {
      try {
        let componentCss = toJSON(component.style.cssBefore).attributes;
        var componentBeforeCss = '';
        for (let key in componentCss) {
          if (CSS.supports(key, componentCss[key])) {
            componentBeforeCss += key + ':' + componentCss[key] + ';';
          }
        }
        // now this is a bit naighty.  Add css to the head here...
        // remove the old
        customCss +=
          '#component-ys-' +
          component.id +
          '::before, #component-' +
          component.id +
          '::before{' +
          componentBeforeCss +
          '}';
      } catch (error: any) {}
    }

    if (component.style.cssHover) {
      try {
        let componentCss = toJSON(component.style.cssHover).attributes;
        var componentBeforeCss = '';
        for (let key in componentCss) {
          if (CSS.supports(key, componentCss[key])) {
            componentBeforeCss += key + ':' + componentCss[key] + ';';
          }
        }
        // now this is a bit naighty.  Add css to the head here...
        // remove the old
        customCss +=
          '#component-ys-' +
          component.id +
          ':hover, #component-' +
          component.id +
          ':hover{' +
          componentBeforeCss +
          '}';
      } catch (error: any) {}
    }

    var elem = document.querySelector('#customCss' + component.id);
    if (elem?.textContent != customCss) {
      elem?.parentNode?.removeChild(elem);
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.appendChild(document.createTextNode(customCss));
      style.setAttribute('id', 'customCss' + component.id);
      head.appendChild(style);
    }

    // gradients

    if (
      component.style.bgGradient &&
      component.style.bgGradient.settings &&
      component.style.bgGradient.settings.type
    ) {
      let bgString = '';

      switch (component.style.bgGradient.settings.type) {
        default:
        case 'linear':
          bgString +=
            'linear-gradient(' +
            component.style.bgGradient.settings.rotation +
            'deg,';
          component.style.bgGradient.colors
            .sort((a: any, b: any) => a.position - b.position)
            .forEach((color: any) => {
              bgString += color.color + ' ' + color.position + '%,';
            });
          bgString = bgString.substring(0, bgString.length - 1);
          bgString += ')';
          break;
      }
      style.background = bgString;
    }

    // pick

    if (component.style.itemTextColor) {
      style['--ys-component-pick-item-text-color'] =
        component.style.itemTextColor;
    }
    if (component.style.itemBg) {
      style['--ys-component-pick-item-bg-color'] = component.style.itemBg;
    }
    if (component.style.itemHoverTextColor) {
      style['--ys-component-pick-item-hover-text-color'] =
        component.style.itemHoverTextColor;
    }
    if (component.style.itemHoverBg) {
      style['--ys-component-pick-item-hover-bg-color'] =
        component.style.itemHoverBg;
    }
    if (component.style.itemPickedTextColor) {
      style['--ys-component-pick-item-picked-text-color'] =
        component.style.itemPickedTextColor;
    }
    if (component.style.itemPickedBg) {
      style['--ys-component-pick-item-picked-bg-color'] =
        component.style.itemPickedBg;
    }
    if (
      component.style.itemPickedOutline &&
      component.style.itemPickedOutline.width &&
      component.style.itemPickedOutline.style &&
      component.style.itemPickedOutline.color
    ) {
      style['--ys-component-pick-item-picked-outline'] =
        component.style.itemPickedOutline.width +
        'px ' +
        component.style.itemPickedOutline.style +
        ' ' +
        component.style.itemPickedOutline.color;
    }

    if (component.style.transition) {
      style['--ys-component-transition'] = component.style.transition;
    }

    // does it have an on pressed action?
    if (
      (component.data &&
        component.data.actions &&
        component.data.actions.length > 0) ||
      component.interactivity?.popover?.template?.trigger ||
      component.interactivity?.window?.trigger
    ) {
      let hasPress = false;
      component.data.actions.forEach((action: any) => {
        action.events.forEach((event: any, eIndex: number) => {
          if (event.id == 'onPress') {
            hasPress = true;
          }
        });
      });

      if (
        component.interactivity?.popover?.template?.trigger == 'click' ||
        component.interactivity?.popover?.template?.trigger == 'dblclick' ||
        component.interactivity?.window?.trigger == 'click' ||
        component.interactivity?.window?.trigger == 'dblclick'
      ) {
        hasPress = true;
      }

      if (hasPress) {
        style['cursor'] = 'pointer';
      }
    }
    if (component.style.overflow) {
      style['--ys-component-overflow'] = component.style.overflow;
    }
    return style;
  }

  getComponentClass(component: any, mode = 'editor', scenario: any) {
    // console.log('component class' + Date.now() );
    let className;
    if (component.style.class) {
      className = component.style.class;
    }
    if (component.style.subClass) {
      className += ' ' + component.style.subClass;
    }
    // animated?
    if (
      component.type == 'choices' &&
      scenario.style.choiceAnimation &&
      !component.style.animationIn
    ) {
      if (scenario.style.choiceAnimation.animationIn) {
        if (typeof scenario.style.choiceAnimation.animationIn == 'string') {
          className +=
            ' animate__animated animate__delay-1s animate__' +
            scenario.style.choiceAnimation.animationIn;

          if (scenario.style.choiceAnimation.animationInRepeat) {
            if (
              scenario.style.choiceAnimation.animationInRepeat == 'infinite'
            ) {
              className += ' animate__infinite';
            } else {
              className += ' animate__repeat-1';
            }
          }
        } else {
          className +=
            ' animate__animated animate__delay-1s animate__' +
            scenario.style.choiceAnimation.animationIn.name;

          if (scenario.style.choiceAnimation.animationIn.repeat) {
            if (
              scenario.style.choiceAnimation.animationIn.repeat == 'infinite'
            ) {
              className += ' animate__infinite';
            } else {
              className += ' animate__repeat-1';
            }
          }
        }
      }
    }

    if (component.animationIn) {
      if (typeof component.animationIn == 'string') {
        className +=
          ' animate__animated animate__delay-1s animate__' +
          component.animationIn;

        if (component.animationInRepeat) {
          if (component.animationInRepeat == 'infinite') {
            className += ' animate__infinite';
          } else {
            className += ' animate__repeat-1';
          }
        }
      } else {
        // is there a name?
        if (component.animationIn.name != '') {
          className +=
            ' animate__animated animate__delay-1s animate__' +
            component.animationIn.name;

          if (component.animationIn.repeat) {
            if (component.animationIn.repeat == 'infinite') {
              className += ' animate__infinite';
            } else {
              className += ' animate__repeat-1';
            }
          }
        } else if (
          component.animationDuring &&
          component.animationDuring?.name != ''
        ) {
          className +=
            ' animate__animated animate__delay-1s animate__' +
            component.animationDuring.name;

          if (component.animationDuring.repeat) {
            if (component.animationDuring.repeat == 'infinite') {
              className += ' animate__infinite';
            } else {
              className += ' animate__repeat-1';
            }
          }
        }
      }
    }

    if (mode == 'player' && component.type != 'choices') {
      if (component.style.hoverEffect) {
        className += ' ' + component.style.hoverEffect.name;
      }
    }

    return className;
  }

  getEditorComponentWrapStyle(component: any, activeComponent?: any) {
    let style: any;
    style = {};
    let rotate = component.style.rotate;
    if (
      !component.style.rotate &&
      component.transform &&
      component.transform.rotate
    ) {
      rotate = component.transform.rotate;
    }

    style['transform'] = '';
    if (rotate) {
      if (!activeComponent) {
        style['transform'] = 'rotate(' + rotate + 'deg) ';
      } else {
        if (activeComponent.id != component.id) {
          style['transform'] = 'rotate(' + rotate + 'deg) ';
        }
      }
    }

    if (component.style.flip.h) {
      style['transform'] += 'scaleX(-1)';
    }

    if (component.style.flip.v) {
      style['transform'] += 'scaleY(-1)';
    }

    if (component.style.zIndex) {
      style['z-index'] = component.style.zIndex;
    }

    if (
      component.type == 'image' &&
      component.style.shadow &&
      component.style.shadow.offsetX
    ) {
      style['--ys-component-shadow'] =
        component.style.shadow.offsetX +
        'px ' +
        component.style.shadow.offsetY +
        'px ' +
        component.style.shadow.blur +
        'px ' +
        component.style.shadow.spread +
        'px ' +
        component.style.shadow.color;

      style['--ys-component-drop-shadow'] =
        component.style.shadow.offsetX +
        'px ' +
        component.style.shadow.offsetY +
        'px ' +
        component.style.shadow.blur +
        'px ' +
        component.style.shadow.color;
    }

    return style;
  }

  getComponentWrapStyle(component: any) {
    let style: any;
    style = {};

    style['transform'] = '';
    'translate3d(' + component.pos.x + 'px, ' + component.pos.y + 'px ,0)';

    let rotate = component.style.rotate;
    if (
      !component.style.rotate &&
      component.transform &&
      component.transform.rotate
    ) {
      rotate = component.transform.rotate;
    }
    if (rotate) {
      style['transform'] += ' rotate(' + rotate + 'deg)';
    }

    if (component.style.flip.h) {
      style['transform'] += ' scaleX(-1)';
    }

    if (component.style.flip.v) {
      style['transform'] += ' scaleY(-1)';
    }
    if (component.style.zIndex) {
      //style['z-index'] = component.style.zIndex;
    }

    style['width'] = component.width + 'px';
    style['height'] = component.height + 'px';

    /* if (component.style.display) {
      style['display'] = component.style.display;
    }*/

    // transition
    if (component.style.transition) {
      style['--ys-component-transition'] = component.style.transition;
    }

    if (component.style.shadow && component.style.shadow.offsetX) {
      style['--ys-component-drop-shadow'] =
        component.style.shadow.offsetX +
        'px ' +
        component.style.shadow.offsetY +
        'px ' +
        component.style.shadow.blur +
        'px ' +
        component.style.shadow.color;
    }

    return style;
  }

  getChoiceStyle(component: any) {
    let style: any;
    style = {};
    //style['width'] = component.width;
    //style['height'] = component.height;

    if (component.style.padding != undefined) {
      style['padding'] = component.style.padding + 'px';
    }
    if (component.style.bg) {
      style['background-color'] = component.style.choiceBg;
    }
    if (component.style.color) {
      style['color'] = component.style.color;
    }
    if (component.style.radius != undefined) {
      style['border-radius'] = component.style.radius + 'px';
    }

    if (component.style.textAlign) {
      style['text-align'] = component.style.textAlign;
    }

    if (component.style.choiceFontWeight) {
      style['--ys-component-choice-font-weight'] =
        component.style.choiceFontWeight + '%';
    }
    if (component.style.choiceFontSize) {
      style['--ys-component-choice-text-scale'] =
        component.style.choiceFontSize + '%';
    }
    if (component.style.choiceLineHeight) {
      style['--ys-component-choice-line-height'] =
        component.style.choiceLineHeight + '%';
    }
    if (component.style.choiceLetterSpacing) {
      style['--ys-component-choice-letter-spacing'] =
        (component.style.choiceLetterSpacing - 100) / 100 + 'em';
    }
    if (component.style.opacity || component.style.opacity == 0) {
      style['opacity'] = component.style.opacity + '%';
    }
    if (component.style.blur) {
      style['filter'] = 'blur(' + component.style.blur + 'px)';
    }

    return style;
  }
  getChoiceClass(component: any) {
    let className;

    if (component.style.choiceStyle) {
      className = component.style.choiceStyle;
    }
    if (component.style.choiceStyleSubClass) {
      className += ' ' + component.style.choiceStyleSubClass;
    }
    if (component.style.hoverEffect) {
      className += ' animate__animated ' + component.style.hoverEffect.name;
    }

    return className;
  }

  getCanvasStyle(content: any, scenarioStyle: any) {
    let style: any;
    style = {};
    if (content && content.canvas) {
      if (!content.canvas.style) {
        content.canvas.style = {};
      }
      if (content.canvas.style.bg) {
        style['background-color'] = content.canvas.style.bg;
      } else {
        if (scenarioStyle.bg) {
          style['background-color'] = scenarioStyle.bg;
        }
      }

      if (content.canvas.style.bgImage) {
        style['background-image'] =
          'url("' + content.canvas.style.bgImage + '")';
        style['background-repeat'] = 'no-repeat';
      }

      if (content.canvas.style.bgImageSize) {
        style['background-size'] = content.canvas.style.bgImageSize;
      }
      if (content.canvas.style.bgImagePosition) {
        style['background-position'] = content.canvas.style.bgImagePosition;
      }

      if (!content.canvas.style.bgImage && scenarioStyle.bgImage) {
        style['background-image'] = 'url("' + scenarioStyle.bgImage + '")';
        style['background-repeat'] = 'no-repeat';

        if (scenarioStyle.bgImageSize) {
          style['background-size'] = scenarioStyle.bgImageSize;
        }
        if (scenarioStyle.bgImagePosition) {
          style['background-position'] = scenarioStyle.bgImagePosition;
        }
      }
    }
    return style;
  }

  // chart service?

  getChartDataSet(component: any, mode: string) {
    let labels = JSON.parse(JSON.stringify(component.chart.labels));
    let dataset = JSON.parse(JSON.stringify(component.chart.data));

    if (mode == 'player') {
      dataset[0].data.forEach((_var: any, _index: any) => {
        dataset[0].data[_index] = this.playerService.parseVars(_var);
      });
    }
    return {
      labels: labels,
      datasets: dataset,
    };
  }

  getChartOptions(component: any, scenario: any, mode?: any) {
    let fontFamily = 'Manrope';
    // fonts...
    if (component.style.font) {
      switch (component.style.font) {
        case 'body':
          fontFamily = scenario.style.fontFamily;
          break;
        case 'sans':
          fontFamily = scenario.style.fontSans;
          break;
        case 'serif':
          fontFamily = scenario.style.fontSerif;
          break;
        case 'heading':
          fontFamily = scenario.style.fontHeading;
          break;
        case 'mono':
          fontFamily = scenario.style.fontMono;
          break;
        case 'script':
          fontFamily = scenario.style.fontScript;
          break;
        case 'custom':
          fontFamily = component.style.fontFamily;
          break;
      }
    }
    let animations = true;
    if (mode == 'editor') {
      animations = false;
    }
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: animations,
      scales: {
        y: {
          title: {
            display: true,
            text: component.chart.yLabel,
            font: {
              size: 14,
              family: fontFamily,
            },
          },
          ticks: {
            font: {
              size: 16,
              family: fontFamily,
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          title: {
            display: true,
            text: component.chart.xLabel,
            font: {
              size: 14,
              family: fontFamily,
            },
          },
          ticks: {
            font: {
              size: 16,
              family: fontFamily,
            },
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          labels: {
            font: {
              size: 16,
              family: fontFamily,
            },
          },
          display: component.chart.showLegend,
        },
        tooltip: {
          titleFont: {
            size: 16,
            family: fontFamily,
          },
          bodyFont: {
            size: 16,
            family: fontFamily,
          },
        },
      },
    };
  }

  checkChoice(choice: any) {
    let hidden = false;
    if (choice.config) {
      if (choice.config.showWhen) {
        // try it here
      } else if (choice.config.hidden) {
        hidden = true;
      }
    }

    return hidden;
  }

  parseVarsQuill(value: string, variables: any) {
    // get the scenario vars?
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(value, 'text/html');

    // find the vars
    const mentions = htmlDoc.querySelectorAll('span.mention');
    mentions.forEach((mention) => {
      let variableVar = '';
      if (mention.attributes.getNamedItem('data-value')) {
        let _var = mention.attributes.getNamedItem('data-value')?.value;
        variableVar = this.getVarValueByName(_var, variables);
      }
      mention.innerHTML = variableVar;
    });

    return htmlDoc.documentElement.innerHTML;
  }

  getVarValueByName(name: any, variables: Array<any>): string {
    let value = '';
    if (variables) {
      variables.forEach((_var) => {
        if (_var.name == name) {
          value = _var.value;
        }
      });
    }
    return value;
  }

  getImageCropStyle(component: any) {
    let style: any = {};
    if (component.style.imageCrop) {
      if (component.style.imageCrop.w) {
        style.width = component.style.imageCrop.w;
      }
      if (component.style.imageCrop.h) {
        style.height = component.style.imageCrop.h;
      }

      let transforms: any = {};
      // let el = document.getElementById('image-crop-' + component.id);
      // transforms = this.parseTransform(el!.style.transform);

      if (component.style.imageCrop.x) {
        transforms.translateX = component.style.imageCrop.x + 'px';
        //style.left = component.style.imageCrop.x + 'px';
      }
      if (component.style.imageCrop.y) {
        transforms.translateY = component.style.imageCrop.y + 'px';
        //style.top = component.style.imageCrop.y + 'px';
      }

      if (component.style.imageCrop.flip) {
        if (component.style.imageCrop.flip.h) {
          transforms.scaleX = '-1';
        } else {
          transforms.scaleX = null;
        }
        if (component.style.imageCrop.flip.v) {
          transforms.scaleY = '-1';
        } else {
          transforms.scaleY = null;
        }
      }

      let transformString = '';
      Object.keys(transforms).forEach(function (key, index) {
        if (transforms[key]) {
          transformString += key + '(' + transforms[key] + ') ';
        }
      });
      if (transformString != '') {
        style.transform = transformString;
      }
    }
    if (component.style?.imageCrop?.filter) {
      //console.log('filter!');
      let filter = '';
      // this.contentService.parseFilters();
      for (var prop in component.style.imageCrop.filter) {
        if (
          Object.prototype.hasOwnProperty.call(
            component.style.imageCrop.filter,
            prop
          ) &&
          component.style.imageCrop.filter[prop] != 0 &&
          component.style.imageCrop.filter[prop] != null
        ) {
          filter +=
            prop +
            '(' +
            ('' + component.style.imageCrop.filter[prop]) +
            this.getFilterUnit(prop) +
            ') ';
        }
      }
      if (filter) {
        style.filter = filter;
      }
    }

    //if(component.style.imageCrop.clip){
    // style.clipPath = component.style.imageCrop.clip;
    //}

    return style;
  }
  parseTransform(transform: string) {
    return Array.from(transform.matchAll(/(\w+)\((.+?)\)/gm)).reduce(
      (agg, [, fn, val]) => ({
        ...agg,
        [fn]: val,
      }),
      {}
    );
  }

  getFilterUnit(filterId: string) {
    let unit = '';
    environment.filters.forEach((filter: any) => {
      if (filter.id == filterId) {
        unit = filter.unit;
      }
    });
    return unit;
  }

  getBlockStyle(component: any, content: any) {
    let style: any = {};
    let blockComponent: any;
    content.components.forEach((contentComponent: any) => {
      if (contentComponent.type == 'block') {
        if (contentComponent.data.componentIds.indexOf(component.id) !== -1) {
          blockComponent = contentComponent;
        }
      }
    });

    if (blockComponent && blockComponent.style.rotate) {
      // get the middle of this component
      let xBlockMiddle = 0;
      let yBlockMiddle = 0;

      if (!blockComponent.drag.x && !blockComponent.drag.y) {
        xBlockMiddle = blockComponent.pos.x + blockComponent.width / 2;
        yBlockMiddle = blockComponent.pos.y + blockComponent.height / 2;
      } else {
        xBlockMiddle = blockComponent.drag.x + blockComponent.width / 2;
        yBlockMiddle = blockComponent.drag.y + blockComponent.height / 2;
      }

      let xComponentMiddle = component.pos.x + component.width / 2;
      let yComponentMiddle = component.pos.y + component.height / 2;

      style['transform'] = 'rotate(' + blockComponent.style.rotate + 'deg)';
      style['transform-origin'] =
        ' calc(' +
        component.width / 2 +
        'px + ' +
        (xBlockMiddle - xComponentMiddle) +
        'px)  calc(' +
        component.height / 2 +
        'px + ' +
        (yBlockMiddle - yComponentMiddle) +
        'px)';
      //  'transform:rotate(' + blockComponent.style.rotate + 'deg);'
      // +'transform-origin: calc(50% + ' + (xBlockMiddle - xComponentMiddle ) + 'px)  calc(50% + ' +  (yBlockMiddle - yComponentMiddle) + 'px);'
    }

    if (blockComponent && blockComponent.animationIn) {
      style['--animate-duration'] = blockComponent.animationIn.speed + 's';
      style['--animate-delay'] = blockComponent.animationIn.delay + 's';

      if (blockComponent.animationIn.repeat) {
        if (blockComponent.animationIn.repeat !== 'infinite') {
          style['--animate-repeat'] = +blockComponent.animationIn.repeat;
        }
      }
    }

    if (blockComponent && blockComponent.style.opacity != undefined) {
      style['opacity'] = blockComponent.style.opacity + '%';
    }

    if (blockComponent && blockComponent.style.blur != undefined) {
      style['filter'] = 'blur(' + blockComponent.style.blur + 'px)';
    }

    return style;
  }

  getBlockClass(component: any, content: any) {
    return '';
  }

  getBlockAnimation(component: any, content: any) {
    let classStr: any = '';
    let blockComponent: any;
    content.components.forEach((contentComponent: any) => {
      if (contentComponent.type == 'block') {
        if (contentComponent.data.componentIds.indexOf(component.id) !== -1) {
          blockComponent = contentComponent;
        }
      }
    });

    if (blockComponent) {
      if (blockComponent.animationIn) {
        classStr +=
          ' animate__animated animate__delay-1s animate__' +
          blockComponent.animationIn.name;

        if (blockComponent.animationIn.repeat) {
          if (blockComponent.animationIn.repeat == 'infinite') {
            classStr += ' animate__infinite';
          } else {
            classStr += ' animate__repeat-1';
          }
        }
      }
    }

    return classStr;
  }

  stringify(object: object) {
    return JSON.stringify(object);
  }

  addAnimations(
    component: any,
    controlVarIn: any,
    controlVarDuring: any,
    controlVarOut: any,
    componentElem: any
  ) {
    if (component[controlVarIn] && component[controlVarIn].name) {
      component[controlVarIn].completed = false;
    }

    if (componentElem) {
      if (component[controlVarIn] && component[controlVarIn].name) {
        componentElem?.style?.setProperty(
          '--animate-duration',
          component[controlVarIn].speed + 's'
        );
        componentElem?.style?.setProperty(
          '--animate-delay',
          component[controlVarIn].delay + 's'
        );

        componentElem?.style?.setProperty(
          '--animate-repeat',
          component[controlVarIn].repeat
        );
      }

      componentElem.addEventListener('animationend', () => {
        if (
          component[controlVarIn] &&
          component[controlVarIn].name &&
          ((component[controlVarDuring] &&
            !componentElem?.classList.contains(
              'animate__' + component[controlVarDuring].name
            )) ||
            !component[controlVarDuring])
        ) {
          component[controlVarIn].completed = true;

          // remove the scenario level choice anim?

          if (component[controlVarIn].name) {
            componentElem?.classList.remove(
              'animate__' + component[controlVarIn].name
            );
            componentElem?.classList.remove('animate__infinite');
          }

          if (component[controlVarDuring] && component[controlVarDuring].name) {
            componentElem?.classList.add(
              'animate__' + component[controlVarDuring].name
            );
            componentElem?.style?.setProperty(
              '--animate-duration',
              component[controlVarDuring].speed + 's'
            );
            componentElem?.style?.setProperty(
              '--animate-delay',
              component[controlVarDuring].delay + 's'
            );

            componentElem?.style?.setProperty(
              '--animate-repeat',
              component[controlVarDuring].repeat
            );
          }
        }
      });

      if (component[controlVarOut] && component[controlVarOut].name) {
        var actionTimeout = setTimeout(() => {
          componentElem?.classList.add(
            'animate__' + component[controlVarOut].name
          );
          componentElem?.style?.setProperty(
            '--animate-duration',
            component[controlVarOut].speed + 's'
          );

          componentElem?.style?.setProperty(
            '--animate-repeat',
            component[controlVarOut].repeat
          );
        }, component[controlVarOut].delay * 1000);
      }
    }
  }
  addEndAnimation() {}
}
