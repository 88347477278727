import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scenarioVars',
  // pure:false
})
export class ScenarioVarsPipe implements PipeTransform {
  transform(value: string, variables: Array<any>): string {

    if(!value){
      return value;
    }
    /*
    variables?.forEach((variable) => {
      let regex =
        `<span class="mention" data-index="1" data-denotation-char="" data-id="` +
        variable.name +
        `" data-value="` +
        variable.name +
        `">﻿<span contenteditable="false"><span class="ql-mention-denotation-char"></span>` +
        variable.name +
        `</span>﻿</span>`;
      value = value.replace(regex, variable.value);
    });
    
    return value;*/

    // new regex
    let regex = /<span class="mention" data-index="[0-9]*" data-denotation-char="" data-id="(\w*)" data-value="(\w*)">(.{0,3})<span contenteditable="false"><span class="ql-mention-denotation-char"><\/span>(\w*)<\/span>(.{0,3})<\/span>/g;
    
    const matched = [...value.matchAll(regex)];
    matched.forEach((match)=>{
      value = value.replace(match[0], this.getVarValueByName(match[1], variables));
    });
    /*
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(value, 'text/html');

    // find the vars
    const mentions = htmlDoc.querySelectorAll('span.mention');
    mentions.forEach((mention) => {
      let variableVar = '';
      if (mention.attributes.getNamedItem('data-value')) {
        let _var = mention.attributes.getNamedItem('data-value')?.value;
        variableVar = this.getVarValueByName(_var, variables);
      }
      mention.innerHTML = variableVar;
    });

    return htmlDoc.documentElement.innerHTML;*/

    return value;
  }

  getVarValueByName(name: any, variables: Array<any>): string {
    let value = '';
    if (variables) {
      variables.forEach((_var) => {
        if (_var.name == name) {
          value = _var.value;
        }
      });
    }
    return value;
  }
}
