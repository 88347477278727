import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { GlobalComponentsComponent } from './player/global-components/global-components.component';
import { PickTaskComponent } from './player/components/pick-task/pick-task.component';
import { TableComponentComponent } from './player/components/table-component/table-component.component';
import { HotspotComponentComponent } from './player/components/hotspot-component/hotspot-component.component';
import { ButtonComponentComponent } from './player/components/button-component/button-component.component';
import { DownloadComponentComponent } from './player/components/download-component/download-component.component';
import { ChatComponentComponent } from './player/components/chat-component/chat-component.component';
import { FormComponentComponent } from './player/components/form-component/form-component.component';
import { QuizComponentComponent } from './player/components/quiz-component/quiz-component.component';
import { PlayerComponent } from './player/player/player.component';
import { InventoryComponent } from './player/inventory/inventory.component';
import { ChartComponentComponent } from './player/components/chart-component/chart-component.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScenarioVarsPipe } from './pipes/scenario-vars.pipe';
import { FormsModule } from '@angular/forms';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { NgChartsModule } from 'ng2-charts';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './loader/loader.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UserTrackingFormComponent } from './player/user-tracking-form/user-tracking-form.component';
import { PlayerModalComponent } from './player/player-modal/player-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { UnavailableComponent } from './unavailable/unavailable.component';
import { EmbedComponentComponent } from './player/components/embed-component/embed-component.component';
import { PlayerHelpComponent } from './player/global-components/player-help/player-help.component';
import { TabsComponentComponent } from './player/components/tabs-component/tabs-component.component';
import { ComponentContainerComponent } from './player/component-container/component-container.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComponentClassPipe } from './pipes/component-class.pipe';
import { ComponentStylePipe } from './pipes/component-style.pipe';
import { BlockClassPipe } from './pipes/block-class.pipe';
import { BlockStylePipe } from './pipes/block-style.pipe';
import { ScenarioStylePipe } from './pipes/scenario-style.pipe';
import { BlockAnimationPipe } from './pipes/block-animation.pipe';
import { ChatAdventureComponentComponent } from './player/components/chat/chat-adventure-component/chat-adventure-component.component';
import { ChatMessengerComponentComponent } from './player/components/chat/chat-messenger-component/chat-messenger-component.component';
import { AnimationClassPipe } from './pipes/animation-class.pipe';
import { AnimationStylePipe } from './pipes/animation-style.pipe';
import { PopoverComponent } from './player/popover/popover.component';
import { PanoramicImageComponentComponent } from './player/components/panoramic-image-component/panoramic-image-component.component';
import { NgxView360Module } from '@egjs/ngx-view360';

@NgModule({
  declarations: [
    AppComponent,
    PickTaskComponent,
    TableComponentComponent,
    HotspotComponentComponent,
    ButtonComponentComponent,
    DownloadComponentComponent,
    ChatComponentComponent,
    FormComponentComponent,
    QuizComponentComponent,
    PlayerComponent,
    GlobalComponentsComponent,
    InventoryComponent,
    ChartComponentComponent,
    NotFoundComponent,
    SafeHtmlPipe,
    ScenarioVarsPipe,
    Nl2brPipe,
    LoaderComponent,
    UserTrackingFormComponent,
    PlayerModalComponent,
    SafePipe,
    UnavailableComponent,
    EmbedComponentComponent,
    PlayerHelpComponent,
    TabsComponentComponent,
    ComponentContainerComponent,
    ComponentClassPipe,
    ComponentStylePipe,
    BlockClassPipe,
    BlockStylePipe,
    ScenarioStylePipe,
    BlockAnimationPipe,
    ChatAdventureComponentComponent,
    ChatMessengerComponentComponent,
    AnimationClassPipe,
    AnimationStylePipe,
    PopoverComponent,
    PanoramicImageComponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TooltipModule.forRoot(),
    NgChartsModule,
    HttpClientModule,
    DragDropModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    NgxView360Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
